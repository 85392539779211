@font-face {
font-family: '__tiemposFont_b85bab';
src: url(/_next/static/media/a38d1e86292212ef-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: '__tiemposFont_b85bab';
src: url(/_next/static/media/aaa0913c6a63f2b5-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
}@font-face {font-family: '__tiemposFont_Fallback_b85bab';src: local("Arial");ascent-override: 78.92%;descent-override: 21.87%;line-gap-override: 20.16%;size-adjust: 99.22%
}.__className_b85bab {font-family: '__tiemposFont_b85bab', '__tiemposFont_Fallback_b85bab'
}.__variable_b85bab {--tiempos-headline-font: '__tiemposFont_b85bab', '__tiemposFont_Fallback_b85bab'
}

